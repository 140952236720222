<template>
  <div class="flex gap-x-6 items-center">
    <button :disabled="amount <= 0 || amount <= min"
            class="booking-form-button-minus"
            @click="amount--"
    ></button>
    <div :class="{ 'text-tertiary-gray': amount <= 0 }" class="font-bold min-w-18px">
      {{ amount }}
    </div>
    <button class="booking-form-button-plus" :disabled="amount >= max" @click="amount++"></button>
  </div>
</template>

<script>
export default {
  name: 'BookingFormAmountInput',
  components: {},
  props: ['amount', 'min', 'max'],
  data: () => ({}),
  mounted() {
    if (!this.amount) {
      this.amount = 0;
    }
  },
  watch: {
    amount() {
      this.$emit('amount-updated', this.amount);
    }
  }
}
</script>