const bookingFormService = {
    methods: {
        updateAmount(newAmount, type, childIndex = 0) {
            switch (type) {
                case 'adults':
                    this.bookingForm.adults = newAmount;
                    break;
                case 'children':
                    this.bookingForm.amountChildren = newAmount;
                    break;
                case 'childAge':
                    this.bookingForm.children[childIndex] = newAmount;
                    break;
            }
        },
        getISODate(date) {
            return date.toISOString().split('T')[0];
        },
        formatDate(date) {
            date = new Date(date);
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
        },
        redirectToFeratel() {
            let subPage = '';
            switch (this.language) {
                case 'fr-CH':
                    subPage = '/fr/shop/#/hebergements'
                    break;
                case 'en-CH':
                    subPage = '/en/shop/#/accommodations'
                    break;
                default:
                    subPage = '/shop/#/unterkuenfte';
            }
            window.location.href = `${window.location.origin}${subPage}`;
        },
        accommodationSearch() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({event: 'form_submit', formType: 'booking_form'});

            const options = {
                dateFrom: new Date(this.bookingForm.date[0]),
                dateTo: new Date(this.bookingForm.date[1]),
                occupancy1: {
                    adults: this.bookingForm.adults,
                    children: this.bookingForm.children.slice(0, this.bookingForm.amountChildren),
                    units: 1
                }
            };
            dw.accommodationQuickFind('leukerbad', options, this.redirectToFeratel());
        },
        allowedDates(val) {
            if (this.bookingForm.date.length === 1) {
                return val >= this.bookingForm.date[0];
            } else {
                return true;
            }
        }
    }
}

export default bookingFormService;
