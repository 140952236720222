<template>
  <div>
    <div class="home-tabs-mobile-button-container">
      <button class="button home-tabs-mobile-button mb-3" @click="hideForm = !hideForm">
        {{ entry.accommodationTabTitle }}
      </button>
      <!--      probably doesn't work-->
      <!--    <button class="button home-tabs-mobile-button open-ticket-form mb-3">-->
      <!--      {{ entry.bathsTabTitle }}-->
      <!--    </button>-->
      <a :href="entry.shopTabUrl" class="button home-tabs-mobile-button text-center">
        {{ entry.shopTabTitle }}
      </a>
    </div>
    <div :class="{ hidden: hideForm }" class="booking-form mobile">
      <button class="close-booking-form-mobile" :aria-label="translations['ARIA.CLOSE_BOOKING_FORM']"
              @click="hideForm = true">
        <i class="icon-close-big" aria-hidden="true"></i>
      </button>

      <div class="w-full p-gap-lg">
        <v-app>
          <div :class="{ hidden: currentStep !== 1 }">
            <p class="text-xl text-center">{{ translations['FROM'] }} - {{ translations['TO'] }}</p>
            <v-date-picker
                v-model="bookingForm.date"
                :allowed-dates="allowedDates"
                no-title
                scrollable
                range
                show-adjacent-months
                :locale="language"
            />
          </div>

          <div :class="{ hidden: currentStep !== 2 }">
            <p class="text-xl text-center">{{ translations['GUESTS'] }}</p>
            <div class="flex items-center justify-between booking-form-amount">
              <div class="text-md">{{ translations['ADULTS'] }}</div>
              <BookingFormAmountInput :amount="bookingForm.adults" :min="1" :max="10"
                                      @amount-updated="amount => updateAmount(amount, 'adults')"></BookingFormAmountInput>
            </div>
            <div class="border-bottom-tertiary-gray my-3"></div>
            <div class="flex items-center justify-between booking-form-amount">
              <div class="text-md">{{ translations['CHILDREN_WITH_AGE'] }}</div>
              <BookingFormAmountInput :amount="bookingForm.amountChildren" :min="0" :max="6"
                                      @amount-updated="amount => updateAmount(amount, 'children')"></BookingFormAmountInput>
            </div>
          </div>

          <div :class="{ hidden: bookingForm.amountChildren === 0 || currentStep !== stepCount }">
            <p class="text-xl text-center">{{ translations['CHILDREN_AGE_MODAL_TITLE'] }}</p>
            <div class="flex flex-col gap-y-4">
              <div v-for="(n, i) in bookingForm.amountChildren"
                   class="flex items-center justify-between booking-form-amount">
                <label>{{ translations['CHILD_AGE'] }} {{ n }}</label>
                <BookingFormAmountInput :amount="bookingForm.children[i]" :min="0" :max="15"
                                        @amount-updated="amount => updateAmount(amount, 'childAge', i)"></BookingFormAmountInput>
              </div>
            </div>
          </div>
        </v-app>
      </div>
      <div class="bg-tertiary-gray h-0.0625 w-full">
        <div class="bg-primary-red h-full" :class="'w-' + (currentStep * (12 / stepCount)) + '/12'"></div>
      </div>
      <div class="flex justify-center sm:justify-end space-x-4 w-full p-gap-lg">
        <div class="booking-form-back" :class="{ hidden: currentStep === 1 }">
          <button class="button secondary" @click="currentStep--">{{ translations['BACK'] }}</button>
        </div>
        <div :class="{ hidden: currentStep === stepCount }">
          <button class="button secondary" @click="currentStep++">{{ translations['CONTINUE'] }}</button>
        </div>
        <div :class="{ hidden: currentStep !== stepCount }">
          <button class="button secondary" @click="accommodationSearch">{{ translations['SEARCH'] }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VApp, VDatePicker} from 'vuetify/lib';
import BookingFormAmountInput from "./BookingFormAmountInput";
import bookingFormService from "../common/services/bookingFormService";

export default {
  name: 'BookingFormMobile',
  components: {BookingFormAmountInput, VApp, VDatePicker},
  props: ['entry', 'translations', 'language'],
  mixins: [bookingFormService],
  data: () => ({
    hideForm: true,
    currentStep: 1,
    bookingForm: {
      date: [],
      adults: 2,
      amountChildren: 0,
      children: []
    }
  }),
  mounted() {
    const today = new Date();
    let inAWeek = new Date();
    inAWeek.setDate(today.getDate() + 7);
    this.bookingForm.date = [this.getISODate(today), this.getISODate(inAWeek)];
  },
  computed: {
    stepCount() {
      return this.bookingForm.amountChildren > 0 ? 3 : 2;
    }
  },
  methods: {}
}
</script>